export const blogItems = [
    {
        key:1,
        title:'View all',
        value:'all'
    },
    {
        key:2,
        title:'Startup Insights',
        value:'startup-insights'
    },
    {
        key:3,
        title:'Artificial Intelligence',
        value:'ai-machine-learning'
    },
    {
        key:4,
        title:'HealthTech',
        value:'healthtech-innovations'
    },
    {
        key:5,
        title:'Sustainable Tech',
        value:'sustainable-tech'
    },
    {
        key:6,
        title:'Remote Work',
        value:'remote-work-freelancing'
    },
    {
        key:7,
        title:'EdTech & E-Learning',
        value:'edtech-elearning'
    },
    {
        key:8,
        title:'Digital Marketing',
        value:'digital-marketing-strategies'
    },
    {
        key:9,
        title:'Product Reviews',
        value:'tech-product-reviews'
    },
]
